import { createRouter, createWebHistory, type Router, type RouteRecordRaw } from 'vue-router';

import AppLayout from '@/layout/AppLayout.vue';
import {
    ASSORTMENT_PLANS_ROUTES,
    DIRECTORY_ACTUAL_GOODS_ROUTES,
    DIRECTORY_ATTRIBUTES_ROUTES,
    DIRECTORY_BRANDS_ROUTES,
    DIRECTORY_CATEGORY_GOODS_ROUTES,
    DIRECTORY_CONTRACTORS_ROUTES,
    DIRECTORY_DEPARTMENTS_ROUTES,
    DIRECTORY_DISTRIBUTORS_ROUTES,
    DIRECTORY_EKG_ROUTES,
    DIRECTORY_GOODS_ROUTES,
    DIRECTORY_PRODUCERS_ROUTES,
    INDIVIDUAL_CONDITIONS_ROUTES,
    MARKETING_EVENTS_ROUTES,
    PAYMENT_OBLIGATIONS_ROUTES,
    REALIZATION_ROUTES,
    WELCOME_ROUTES,
    WIZARD_MARKETING_EVENTS_ROUTES,
    DIRECTORY_CANDIDATES_DEPARTMENTS_ROUTES,
    DIRECTORY_CANDIDATES_DISTRIBUTORS_ROUTES,
    DIRECTORY_CONCERN_ROUTES,
    DIRECTORY_COMPANY_NET_ROUTES,
    DIRECTORY_CONTRACTORS_GROUP_ROUTES,
    DIRECTORY_DEPARTMENT_TYPES_ROUTES,
    DIRECTORY_STU_ROUTES
} from '@/router/directories';
import { directoryRoutesGenerator } from '@/router/utilities/directoryRoutesGenerator';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'main',
        redirect: { name: 'welcome' },
        component: AppLayout,
        children: [
            WELCOME_ROUTES,
            DIRECTORY_DEPARTMENTS_ROUTES,
            DIRECTORY_CONTRACTORS_ROUTES,
            DIRECTORY_EKG_ROUTES,
            DIRECTORY_PRODUCERS_ROUTES,
            DIRECTORY_GOODS_ROUTES,
            DIRECTORY_ATTRIBUTES_ROUTES,
            DIRECTORY_CATEGORY_GOODS_ROUTES,
            DIRECTORY_ACTUAL_GOODS_ROUTES,
            ...directoryRoutesGenerator(),
            DIRECTORY_CONCERN_ROUTES,
            DIRECTORY_DISTRIBUTORS_ROUTES,
            MARKETING_EVENTS_ROUTES,
            WIZARD_MARKETING_EVENTS_ROUTES,
            ASSORTMENT_PLANS_ROUTES,
            REALIZATION_ROUTES,
            INDIVIDUAL_CONDITIONS_ROUTES,
            PAYMENT_OBLIGATIONS_ROUTES,
            DIRECTORY_BRANDS_ROUTES,
            DIRECTORY_CANDIDATES_DEPARTMENTS_ROUTES,
            DIRECTORY_CANDIDATES_DISTRIBUTORS_ROUTES,
            DIRECTORY_COMPANY_NET_ROUTES,
            DIRECTORY_CONTRACTORS_GROUP_ROUTES,
            DIRECTORY_DEPARTMENT_TYPES_ROUTES,
            DIRECTORY_STU_ROUTES
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/views/pages/NotFound/NotFoundPage.vue')
    }
];

const router: Router = createRouter({
    history: createWebHistory(),
    routes
});

//Надо написать доку для этой хуйни, но вроде как эта залупа для визарда
router.beforeEach((to, from, next) => {
    if (to.params.originalEventId && /[a-zA-Z]/.test(<string>to.params.originalEventId)) {
        to.params = { originalEventId: '', action: to.params.originalEventId, eventId: to.params.action };
    }
    if (to.matched.some((record) => record.meta.idRequired)) {
        if (!to.params.eventId) {
            next({
                name: 'event'
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
